.container {
  margin: 0 auto;
  width: calc(100vw - 72px);
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
}

.header {
  margin-bottom: 15px;
  display: flex;
}

.headerSearch {
  margin-left: 20px;
}

.loadingWrapper {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container .accordionPreview {
  display: flex;
  align-items: center;
  background-color: #e6eefe;
}

.container .accordionPreviewEmail {
  margin-right: auto;
  align-self: center;
}

.container .accordionPreviewInfo {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.container .accordionPreviewInfoItem {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.container .accordionPreviewInfoItem > svg {
  margin-right: 5px;
}

.container .accordionDetails {
  background: #f1f1f1;
}

/*.container .accordionDetails,*/
/*.accountsListContainer {*/
/*  border-top: 1px solid #ccc;*/
/*}*/

.accountsListContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accountsListContainerLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgb(255 255 255 / 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.accountsListContainerTitle {
  border-bottom: 1px solid currentColor;
  display: inline-block;
}

.accordionDetailsInfo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.paginationWrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.tableFooter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid #eaecee;
  max-width: calc(100% - 44px);
}

.tableFooter .searchField {
  margin-left: 8px;
}

.creatorDescr {
  display: flex;
  flex-direction: column;
}

.creatorDescrInner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagDescr {
  text-align: center;
}
