.button {
  border: none;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 112px;
  height: 36px;
  padding: 18px 16px;
  border-radius: 40px;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-family: inherit;
}

.button:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.button.primary {
  background: rgba(64, 123, 242, 1);
  color: #ffffff;
}

.button.secondary {
  background-color: #ffffff;
  color: #007bff;
}

.button.green {
  color: #ffffff;
  background-color: #44cb2e;
}

.button.grey {
  color: #9ca3af;
  background-color: #ffffff;
  transition: all 0.3s linear;
}

.button.border {
  border: 2px solid #b3ccfd;
}

.button.primary:hover {
  background-color: #0066d3;
}

.button.secondary:hover {
  background-color: #a9d3ff;
}

.button.grey:hover {
  background-color: #d1d5db;
  transition: all 0.1s linear;
}

.button:focus {
  outline: 0;
}
