.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.3;
}

.content {
  max-height: 620px;
  min-height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-color: rgb(209 213 219);
  border-radius: 0.375rem;
  border-width: 1px;
  background-color: rgb(255 255 255);
  position: absolute;
  z-index: 10;
  min-width: 230px;
  padding: 0.75rem 1rem;
  width: 75vw;
  max-width: 1200px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.editor {
  width: 100%;
  max-width: 100% !important;
  overflow-y: auto;
  font-size: 14px !important;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.3;
  top: 0;
  left: 0;
}
