.container {
  margin: 0 auto;
  width: calc(100vw - 72px);
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.header {
  margin-bottom: 15px;
  display: flex;
}

.headerSearch {
  margin-left: 20px;
}
