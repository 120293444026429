.tableWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1;
}

.loadingWrapper {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
