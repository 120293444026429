.dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.backdrop {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
}

.content {
    width: 500px;
    max-height: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: rgb(209 213 219);
    border-radius: 0.375rem;
    border-width: 1px;
    background-color: rgb(255 255 255);
    position: absolute;
    z-index: 10;
    min-width: 230px;
    padding: 0.75rem 1rem;
    width: 75vh;
    z-index: 9999;
}

.small {
    max-height: 700px;
}