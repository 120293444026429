.container {
  margin: 0 auto;
  width: calc(100vw - 72px);
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1;
}

.loadingWrapper {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
