.wrapper {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0 4px;
  background-color: transparent;
  transition: all 0.5s;
}

.wrapper:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
