.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  gap: 5px;
}

.input {
  font-weight: 400;
  width: 100%;
}
