.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.confirmationText {
  font-weight: 600;
  font-size: 18px;
  margin: 15px auto;
  text-align: center;
  flex-grow: 1;
}

.btnBlock {
  display: flex;
  gap: 20px;
  justify-content: center;
}
