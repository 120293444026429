.container {
  margin: 0 auto;
  width: calc(100vw - 72px);
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
}
