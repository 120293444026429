.wrapper {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}
