@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Manrope;
  src: url('../public/fonts/Manrope-Regular.ttf');
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #a8b0b9;
  border-radius: 10px;
}

html {
  @apply font-manrope;
}
body {
  @apply text-sm;
  @apply m-0;
  @apply h-full;
  @apply overflow-hidden;
}
input.fcrm-email input.fcrm-password,
input.fcrm-email::placeholder,
input.fcrm-password::placeholder,
button.fcrm-submit {
  @apply text-gray-600;
  @apply font-medium;
  @apply leading-p-140;
  @apply tracking-tight;
}
input.fcrm-email,
input.fcrm-password {
  @apply block;
  @apply w-full;
  @apply bg-white;
  @apply border;
  @apply appearance-none;
  @apply focus:outline-none;
  @apply focus:ring-0;
  @apply focus:border-gray-400;
  @apply border-gray-300;
  @apply rounded;
}
button.fcrm-submit {
  @apply text-white;
  @apply uppercase;
}
input.fcrm-email:focus::placeholder,
input.fcrm-password:focus::placeholder {
  @apply text-transparent;
}
.fcrm-error-i {
  @apply text-red-600;
  @apply text-xs;
  @apply font-normal;
}
.fcrm-h3 {
  @apply font-semibold;
  @apply text-2xl;
  @apply tracking-[-0.04em];
  @apply text-gray-900;
}
.fcrm-info-text {
  @apply text-base;
  @apply font-normal;
  @apply tracking-[-0.02em];
  @apply text-gray-600;
  @apply text-center;
}
/* select -- begin */
.fcrm-sel-container {
  @apply w-full;
  @apply mt-6;
  @apply max-h-[416px];
  @apply bg-white;
  @apply rounded-xl;
  @apply drop-shadow-m-list;
  @apply overflow-scroll;
}
.fcrm-sel-container:hover {
  @apply drop-shadow-m-list-act;
}
.fcrm-sel-item {
  @apply relative;
  @apply text-sm;
  @apply font-semibold;
  @apply tracking-[-0.02em];
  @apply text-gray-900;
  @apply px-4;
  @apply py-4;
  @apply border-b;
  @apply border-gray-200;
}
.fcrm-sel-item::before {
  @apply w-2.5;
  @apply h-2.5;
  @apply absolute;
  @apply content-[''];
  @apply border-t;
  @apply border-r;
  @apply border-gray-500;
  @apply top-5;
  @apply right-7;
  @apply z-10;
  @apply rotate-45;
}
.fcrm-sel-item:hover {
  @apply bg-blue-100;
}
.fcrm-sel-item:hover,
.fcrm-sel-item:has(+ .fcrm-sel-item:hover) {
  @apply cursor-pointer;
  @apply text-black;
  @apply border-gray-300;
}
/* select -- end */
/* loading - begin */
.fcrm-loading {
  @apply inline-block;
  @apply relative;
  @apply w-20;
  @apply h-20;
  transform: translateY(50%);
}
.frcm-l-frame-1,
.frcm-l-frame-2,
.frcm-l-frame-3,
.frcm-l-frame-4 {
  @apply absolute;
  @apply top-[33px];
  @apply w-[13px];
  @apply h-[13px];
  @apply rounded-[50%];
  @apply bg-major;
  @apply ease-loading-t;
}
.frcm-l-frame-1,
.frcm-l-frame-2 {
  @apply left-2;
  @apply animate-loading-1;
}
.frcm-l-frame-3 {
  @apply left-8;
}
.frcm-l-frame-2,
.frcm-l-frame-3 {
  @apply animate-loading-2;
}
.frcm-l-frame-4 {
  @apply left-14;
  @apply animate-loading-3;
}
/* loading - end */
.fcrm-form-dialog,
.fcrm-form-dialog-plain {
  @apply w-[75vh];
  @apply min-w-[230px];
  @apply h-fit;
  @apply px-4;
  @apply py-3;
}
.fcrm-form-dialog {
  @apply absolute;
  @apply z-10;
  @apply bg-white;
  @apply border;
  @apply border-gray-300;
  @apply rounded-md;
  @apply drop-shadow-md;
}
.fcrm-form-d-item {
  @apply w-full;
  @apply py-2.5;
  @apply px-4;
  @apply border;
  @apply border-gray-300;
  @apply rounded-md;
}
.fcrm-form-d-item-link {
  @apply py-2.5;
  @apply px-4;
  @apply border;
  @apply border-gray-300;
  @apply rounded-md;
  @apply truncate;
}
.fcrm-greed-row {
  @apply w-full;
  @apply m-auto;
  @apply flex;
  @apply flex-row;
  @apply items-start;
  @apply text-ellipsis;
  @apply whitespace-nowrap;
}
.fcrm-greed-row:nth-child(even) {
  @apply bg-gray-100;
}
.fcrm-greed-col,
.fcrm-greed-h-col {
  @apply px-4;
  @apply py-3;
  @apply text-ellipsis;
}
.fcrm-greed-h-col {
  @apply py-1.5;
}

/* top menu - begin */
.fcrm-i-text,
.fcrm-i-text-h {
  @apply text-xs;
  @apply font-medium;
}
.fcrm-i-text {
  @apply text-gray-900;
}
.fcrm-i-text-h {
  @apply text-gray-500;
}
.frcm-blue-btn,
.frcm-red-btn {
  @apply bg-blue-500;
  @apply rounded-full;
  @apply relative;
  @apply flex;
  @apply flex-row;
  @apply items-center;
  @apply text-white;
  @apply font-semibold;
  @apply text-sm;
  @apply min-w-btn;
}
.frcm-red-btn {
  @apply bg-red-600;
  @apply min-w-[35px];
}
.frcm-blue-btn:hover,
.frcm-red-btn:hover {
  @apply cursor-pointer;
  @apply bg-blue-600;
}
.frcm-red-btn:hover {
  @apply bg-red-700;
}
.fcrm-user-logo {
  @apply bg-green-500;
  @apply rounded-full;
  @apply w-8;
  @apply h-8;
  @apply text-white;
  @apply text-xs;
  @apply font-medium;
  @apply text-center;
  @apply py-2;
}
.fcrm-todo-btn {
  @apply text-blue-500;
  @apply rounded-full;
  @apply font-semibold;
  @apply text-sm;
  @apply text-center;
  @apply border-blue-300;
  @apply border-2;
  @apply py-2;
  @apply px-7;
  @apply min-w-btn;
}
.fcrm-drop-down-i:hover,
.fcrm-todo-btn:hover {
  @apply cursor-pointer;
}
.fcrm-todo-btn:hover {
  @apply text-blue-600;
  @apply border-blue-400;
}
.fcrm-notification-i:hover {
  @apply cursor-pointer;
}
/* Drop down menu -- begin */
.fcrm-drpd-menu {
  @apply relative;
  @apply px-5;
  @apply pt-3.5;
}
.fcrm-drpd-menu::before {
  @apply w-4;
  @apply h-4;
  @apply absolute;
  @apply content-[''];
  @apply bg-white;
  @apply border-t;
  @apply border-l;
  @apply border-gray-300;
  @apply top-[-8px];
  @apply right-3;
  @apply z-10;
  @apply rotate-45;
}
.fcrm-menu-h {
  @apply mb-2;
  @apply text-gray-900;
  @apply text-lg;
  @apply font-semibold;
  @apply tracking-[-0.04em];
}
.fcrm-menu-i {
  @apply relative;
  @apply flex;
  @apply items-center;
  @apply h-9;
  @apply my-1;
  @apply text-left;
  @apply text-blue-500;
  @apply font-semibold;
  @apply text-sm;
  @apply tracking-[-0.02em];
}
.fcrm-menu-i:hover {
  @apply cursor-pointer;
  @apply text-blue-600;
}
.fcrm-menu-bullet {
  @apply ml-0.5;
  @apply mt-[-10px];
}
/* Drop down menu -- end */
.fcrm-tmenu-ddown-item-t {
  @apply mx-4;
  @apply my-3;
  @apply cursor-pointer;
}
.fcrm-tmenu-ddown-item {
  right: 0;
  @apply absolute;
  @apply z-10;
  @apply bg-white;
  @apply border;
  @apply border-gray-300;
  @apply rounded-md;
  @apply drop-shadow-md;
}
.fcrm-tmenu-ddown-item:hover {
  @apply bg-gray-100;
}
/* top menu - end */

/* left menu - begin */
.fcrm-lmenu-btn,
.fcrm-lmenu-btn-s,
.fcrm-lmenu-btn-ws,
.fcrm-lmenu-btn-ws-s {
  @apply relative;
  @apply bg-transparent;
  @apply w-major-72;
  @apply h-item-52;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}
.fcrm-lmenu-btn.small,
.fcrm-lmenu-btn-s.small,
.fcrm-lmenu-btn-ws.small,
.fcrm-lmenu-btn-ws-s.small {
  @apply w-12;
  @apply h-11;
}
.fcrm-lmenu-btn-wrap,
.fcrm-u-btn-wrap,
.fcrm-lmenu-btn-wrap-h,
.fcrm-u-btn-wrap-h,
.fcrm-lmenu-btn-wrap-a,
.fcrm-u-btn-wrap-a {
  @apply relative;
  @apply w-major-198;
  @apply flex;
  @apply place-items-center;
  @apply justify-center;
  @apply h-16;
}
.fcrm-lmenu-btn-txt {
  @apply w-major-126;
  @apply h-item-52;
  @apply flex;
  @apply items-center;
  @apply justify-start;
  @apply text-sm;
  @apply font-semibold;
  @apply tracking-[-0.02em];
  @apply text-gray-300;
  @apply whitespace-nowrap;
}
.fcrm-lmenu-btn-txt.small {
  @apply h-11;
}
.fcrm-u-btn-wrap,
.fcrm-u-btn-wrap-h,
.fcrm-u-btn-wrap-a {
  @apply w-full;
}
.fcrm-lmenu-btn-wrap-a,
.fcrm-u-btn-wrap-a {
  @apply bg-gray-900;
}
.fcrm-lmenu-btn-i-ws,
.fcrm-lmenu-btn-i-ws-s,
.fcrm-lmenu-btn-i,
.fcrm-lmenu-btn-i-s {
  @apply fill-transparent;
}
.fcrm-lmenu-btn-i,
.fcrm-lmenu-btn-i-s {
  @apply stroke-blue-300;
  @apply stroke-1.5;
}
.fcrm-lmenu-btn-i-ws-s {
  @apply stroke-1;
}
.fcrm-lmenu-btn-i-s,
.fcrm-lmenu-btn-i-ws-s {
  @apply fill-blue-50;
}
.fcrm-lmenu-btn-i-s {
  @apply stroke-none;
}
.fcrm-lmenu-btn-wrap:hover,
.fcrm-u-btn-wrap:hover,
.fcrm-lmenu-btn-wrap-a:hover,
.fcrm-u-btn-wrap-a:hover {
  @apply cursor-pointer;
}
.fcrm-lmenu-btn-wrap-h,
.fcrm-u-btn-wrap-h,
.fcrm-lmenu-btn-wrap:hover,
.fcrm-u-btn-wrap:hover {
  @apply bg-gray-800;
  @apply cursor-pointer;
}
.fcrm-lmenu-btn-wrap-h .fcrm-lmenu-btn-i,
.fcrm-lmenu-btn-wrap-h .fcrm-lmenu-btn-i-ws,
.fcrm-lmenu-btn-wrap:hover .fcrm-lmenu-btn-i,
.fcrm-lmenu-btn-wrap:hover .fcrm-lmenu-btn-i-ws {
  @apply stroke-blue-500;
}
.fcrm-lmenu-btn-wrap-h .fcrm-lmenu-btn-txt,
.fcrm-u-btn-wrap-h .fcrm-lmenu-btn-txt,
.fcrm-lmenu-btn-wrap:hover .fcrm-lmenu-btn-txt,
.fcrm-u-btn-wrap:hover .fcrm-lmenu-btn-txt {
  @apply text-gray-400;
}
.fcrm-u-avatar {
  @apply w-10;
  @apply h-10;
  @apply rounded-full;
}
.fcrm-u-name,
.fcrm-u-name-verified {
  @apply text-white;
  @apply font-semibold;
  @apply text-sm;
  @apply tracking-[-0.02em];
}
.fcrm-u-name-verified {
  @apply pr-5;
}
.fcrm-u-nname {
  @apply text-gray-500;
  @apply text-xs;
  @apply font-medium;
  @apply pt-1.5;
}
.fcrm-u-activity,
.fcrm-u-activity-num {
  @apply text-gray-500;
  @apply text-sm;
  @apply font-medium;
  @apply tracking-[-0.02em];
}
.fcrm-u-activity-num {
  @apply text-gray-300;
  @apply font-semibold;
}
.fcrm-u-btn-i {
  @apply fill-gray-400;
}
.fcrm-u-btn-i-s {
  @apply stroke-gray-400;
}
.fcrm-u-btn-i.active {
  @apply fill-white;
}
.fcrm-u-btn-wrap:hover .fcrm-u-btn-i {
  @apply fill-gray-500;
}
.fcrm-u-btn-wrap:hover .fcrm-u-btn-i-s {
  @apply stroke-gray-500;
}
/* left menu - end */

/* tabs - begin */
.fcrm-tab-active,
.fcrm-tab {
  @apply px-5;
  @apply py-2.5;
}
.fcrm-tab-active {
  @apply border-b-2;
  @apply border-blue-500;
}
.fcrm-tab-active:hover,
.fcrm-tab:hover {
  @apply cursor-pointer;
}
.fcrm-tab-active:hover {
  @apply bg-blue-200;
}
.fcrm-tab:hover {
  @apply bg-gray-200;
}
/* tabs - end */
/* tooltips - begin */
.fcrm-menu-btn-tooltip {
  @apply relative;
}
.fcrm-menu-btn-tooltip::after {
  @apply opacity-0;
  @apply content-[""];
  @apply absolute;
  @apply z-10;
  @apply rounded-md;
  @apply bg-amber-50;
  @apply drop-shadow-lg;
  @apply top-[-16px];
  @apply right-0;
  @apply text-xs;
  @apply px-2.5;
  @apply py-1.5;
  @apply text-black;
}
.fcrm-menu-btn-tooltip:hover::after {
  @apply opacity-100;
  transition: opacity 0s linear 1s;
}
.fcrm-menu-btn-tooltip-agencies:hover::after {
  @apply content-["Agencies"];
}
.fcrm-menu-btn-tooltip-dashboard:hover::after {
  @apply content-["Dashboard"];
}
.fcrm-menu-btn-tooltip-agency-members:hover::after {
  @apply content-["Users"];
}
.fcrm-menu-btn-tooltip-models::after {
  @apply right-auto;
}
.fcrm-menu-btn-tooltip-models:hover::after {
  @apply content-["Creators"];
}
.fcrm-menu-btn-tooltip-proxies:hover::after {
  @apply content-["Available_proxies"];
}
.fcrm-menu-btn-tooltip-my-profile:hover::after {
  @apply content-["My_profile"];
}
.fcrm-menu-btn-tooltip-remove-chatter:hover::after {
  @apply content-["Unassign_a_chatter_from_the_model"];
}
.fcrm-menu-btn-tooltip-remove-user-from-company:hover::after {
  @apply content-["Unassign_a_user_from_the_company"];
}
.fcrm-menu-btn-tooltip-configuration:hover::after {
  @apply content-["Configu-ration"];
}
/* tooltips - end */

.ant-table-wrapper {
  height: 100%;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.ant-table {
  flex: auto;
  overflow: hidden;
  scrollbar-color: inherit !important;
}

.ant-table-container {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.ant-table-content {
  height: 100%;
}

.ant-table-content table {
  height: 100%;
}

.ant-table-header {
  flex: none;
}

.ant-table-body {
  flex: auto;
  overflow-y: auto !important;
}

.ant-spin {
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-height: none !important;
}

.ant-table-pagination {
  flex: none;
}

.ant-table-cell:not(.ant-table-cell-scrollbar) {
  padding: 0 10px !important;
  height: 52px;
}

.ant-btn-primary {
  color: #fff;
  background: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

/* config page json text editor - textarea for create setting */
div.jer-collection-inner > div.jer-collection-text-edit > div{
  width: 100% !important;
}
